import React from 'react';
import Layout from '../../layouts/Project';
import ProjectIntro from '../../content/ProjectIntro';
import CapsAndTech from '../../content/CapsAndTech';
import Text from '../../content/Text';
import Caption from '../../content/ImageCaption';
import { StaticImage as Image } from 'gatsby-plugin-image';
import Images from '../../content/Images';
import Press from '../../content/Press';
import Credits from '../../content/Credits';

export const frontmatter = {
  title: 'Vortic',
  projectType: 'Products',
  brief: 'Vortic is the best way to experience art online',
  description:
    'Vortic is the art world’s leading extended reality (XR) VFX platform that offers customized and sustainable solutions for galleries to exhibit works of art using the most advanced AR and VR\u00A0technologies.',
  client: ['In Partnership with Victoria Miro Gallery'],
  release: 'Summer 2020',
  urls: { 'vortic.art': 'https://vortic.art/discover' },
  meta: {},
  capabilities: [
    'Product Development',
    'Experience Design',
    'Real-Time',
    'AR, VR',
    'CG & VFX',
    'Software Engineering',
    'Photogrammetry'
  ],
  technology: [
    'HDR Lightbaking',
    'Multiplayer',
    'Webgl',
    'Unity',
    'Swift',
    'GLS',
    'React',
    'WebRTC '
  ],
  credits: {
    Partnership: 'Victoria Miro Gallery',
    'User Experience Design': 'Superbright',
    Photogrammetry: 'Superbright, R01, Alter Equal',
    Engineering: 'Superbright',
    'Graphic Design': 'Long Story Short'
  },
  order: 1
};

const clientLogos = [
  <Image
    src="../../images/work/vortic/victoria-miro-logo.png"
    alt="Victoria Miro Gallery"
    placeholder="none"
  />
];

const Page = () => (
  <Layout title={frontmatter.title}>
    <ProjectIntro frontmatter={frontmatter} clientLogos={clientLogos} />
    <Images>
      <Image
        src="../../images/work/vortic/kusama-gallery.jpg"
        alt="Yayoi Kusama's I Want Your Tears to Flow with the Words I Wrote, on view in Vortic"
      />
      <Caption>Detail of Yayoi Kusama's "I Want Your Tears to Flow with the Words I Wrote", on view in Vortic.</Caption>
    </Images>
    <CapsAndTech frontmatter={frontmatter} />
    <Text large title={'Opportunity'} split={1}>
      <p>
      Superbright partnered with Oliver Miro, the son of art dealer Victoria Miro, to 
      create a proprietary online platform that enables the remote experience of art, 
      designed to provide the galleries with the tools and features needed for long-term 
      engagement.

      </p>
      <p>
      The project developed “very organically”, says Pilar Corrias, who is a member 
      of the London Collective’s newly formed committee alongside Oliver and Victoria 
      Miro, fellow gallery owners Sid Motion, Tommaso Corvi-Mora and Josh Lilley.
      </p>
      <p>
      On 26 June 2020, Vortic launched with the London Collective. An extended reality 
      platform that allows galleries to exhibit works using Augmented Reality (AR) and 
      virtual reality (VR), Vortic allows viewers to virtually try out works in their own homes.
      </p>
    </Text>
    <Images>
      <Image src="../../images/work/vortic/gallery-view_02.jpg" alt="" />
      <Caption>
        Kudzanai-Violet Hwami: When You Need Letters for Your Skin available on Vortic
      </Caption>

      <Image
        src="../../images/work/vortic/kusama-gallery-detail.jpg"
        alt="Detail of Yayoi Kusama's I Want Your Tears to Flow with the Words I Wrote, on view in Vortic"
      />
      <Caption>
        Detail of Yayoi Kusama's I Want Your Tears to Flow with the Words I
        Wrote, on view in Vortic
      </Caption>
    </Images>
    <Text title="Solution">
      <p>
      The Vortic platform comprises four core products — Vortic Curate CMS, Vortic Collect 
      Mobile, Vortic Web App and soon Vortic Art Social VR, all designed and engineered by 
      Superbright and powered by our proprietary, custom 3D-baking engine.
      </p>
      <p>
      Superbright architected the platform based on existing industry-standard VFX principles, 
      choosing to deploy a custom cloud-based baking engine rather than relying on game engine 
      technology. This allows content created by galleries to be easily distributed across 
      multiple platforms, namely webgl, game engines, and mobile devices.
      </p>
      <p>
      The device-agnostic quality of the Superbright engine allows the platform to scale easily 
      (up to 8K resolution) while reducing the cost of creating and managing immersive exhibitions 
      for galleries and institutions.
      </p>
      <p>
        
      </p>
    </Text>
    <Text title="Output" split={2} large>
      <p>
        Vortic is currently being used by over 150 galleries and 30 institutions
        worldwide, all working together to reach a new audience with the objective of
        changing how we see and engage with art digitally.
      </p>
      <p>
      Vortic’s mission is to establish an industry-leading platform that supports the 
      remote experience of art, online. Superbright has created a sustainable tech 
      solution that allows the creation of a cross-platform, XR art exhibition in less 
      than 30 minutes via four unique tools.
      </p>
      <p>
        The <strong>Vortic Curate</strong> CMS enables galleries to ‘hang’ 2D, 3D
        and video artworks using a custom interface that emulates the physical
        process IRL, allowing them to reach collectors and art lovers all over the 
        world with specially curated virtual exhibitions and invite-only experiences.
      </p>
      <p>
        The <strong>Vortic Collect iOS Mobile Application</strong> allows
        collectors and art lovers to view 3D exhibitions on their mobile devices
        while leveraging Augmented Reality technology to view works in their own
        homes, maximizing the collector experience.
      </p>
      <p>
        The <strong>Vortic Art Social VR Application</strong> allows collectors and 
        art lovers to visit exhibitions with the highest-quality rendering using 
        Oculus Quest alongside friends anywhere in the world, courtesy of Superbright’s 
        proprietary multiplayer tech.
      </p>
      <p>
        The <strong>Vortic Art Web Application</strong> allows collectors and
        art lovers to view exhibitions online and participate in virtual events
        where leading curators and galleries discuss their exhibitions.
      </p>
    </Text>

    <Images>
      <Image
        src="../../images/work/vortic/vortic-mobile-vr.001.jpeg"
        alt="Vortic Mobile & VR" 
      /> 
      <Caption>
        Vortic is available on Web, Mobile and soon VR via the Oculus Quest headset
      </Caption>
      <Image
        src="../../images/work/vortic/VORTIC_Don_Langberg_001.jpg"
        alt="After the gold rush"
      />
      <Caption>
      "Doron Langberg: Give Me Love at Victoria Miro
      </Caption>
        <Image
        src="../../images/work/vortic/after-the-gold-rush.jpg"
        alt="After the gold rush"
      />
      <Caption>
      "After The Goldrush" at The Centre of Contemporary Art of Malaga
      </Caption>
      <Image
        src="../../images/work/vortic/OR_Raul_Cardozo_001.jpg"
        alt="OR by Raúl Cardozo"
      />
      <Caption>
      OR by Raúl Cardozo at Fundación Govea Meoz, available on Vortic
      </Caption>
    </Images>
    <Press
      quotes={[
        {
          quote:
            'Our ongoing objective  is to further develop the  platform in collaboration with the art world, making sure they are at the centre of everything we do Making art accessible to everyone via the internet and connected spaces.',
          heading: 'Oliver Miro',
          subheading: 'Vortic Founder'
        },
        {
          quote:
            'The level of detail in Vortic is remarkable - not only can you click on an artwork and zoom in so close as to see the impasto on a painting, but the spaces feel bizarrely like you’re really entering the kind of space you might find somewhere in Mayfair or St James".',
          heading: 'Nancy Durrant',
          subheading: 'Evening Standard',
          date: 'February, 2021'
        },
        {
          quote:
            'Vortic nicely brings together four mainstays of digital art sales/display into one slick platform',
          heading: 'The Art Newspaper',
          url: 'https://www.theartnewspaper.com/review/vortic-collect-review',
          date: 'August, 2020'
        },
        {
          quote:
            'A Famous Art Dealer’s Son Is Launching a New Platform for Galleries to Create Virtual-Reality Exhibitions Using 3D-Scanning Technology',
          heading: 'Artnet',
          url: 'https://news.artnet.com/art-world/new-extended-reality-app-1812807',
          date: ' March, 2020'
        },
        {
          quote:
            'Using ground-breaking software and rendering techniques that have never before been used in the industry, Vortic will be the first platform of its kind to incorporate three-dimensional works including sculpture and textiles, as well as 2D works.',
          heading: 'FAD Magazine',
          url: 'https://fadmagazine.com/2020/03/24/vortic-an-extended-reality-xr-platform-for-the-art-world-announces-launch/',
          date: 'March, 2020'
        }
      ]}
    />
    <Credits credits={frontmatter.credits} />
  </Layout>
);

export default Page;
